import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppState } from 'src/app/store';
import { selectActivitySideBar, toggleSidebar } from 'src/app/core/activity/store';
import { APP_INITIALIZED, THEMEABLE_URL_PATTERNS } from 'src/app/core/app.constants';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ThemeService } from 'src/app/core/services/theme.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-main-content-sidenav',
  templateUrl: './main-content-sidenav.component.html'
})
export class MainContentSidenavComponent implements OnInit, AfterViewInit {
  toggled$: Observable<boolean>;
  isDarkMode$: Observable<boolean>;


  constructor(
    private store: Store<AppState>,
    private themeService: ThemeService,
    private router: Router, 
  ) {}

  ngAfterViewInit(): void {
    /**
     * This method is used to dispatch an action to the store to indicate that the app has been initialized.
     */
    this.store.dispatch(APP_INITIALIZED());
  }

  isThemeable() {
    const baseUrl = this.router.url.split('?')[0];
    return THEMEABLE_URL_PATTERNS.some(pattern => pattern.test(baseUrl));
  }

  ngOnInit(): void {
    this.toggled$ = this.store.select(selectActivitySideBar);
    this.isDarkMode$ = this.themeService.isDarkMode();
  }

  toggleActivityFeed() {
    this.store.dispatch(toggleSidebar({ orgId: null }));
  }
  
}

