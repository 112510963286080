import { Component, inject, Input } from '@angular/core';
import { DynamicComponent } from '../dynamic.component';
import { Activity, deleteActivity } from '../../store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-activity-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements DynamicComponent {

  private store = inject(Store);

  @Input() notification!: Activity;

  constructor() {}

  ngOnInit(): void {}

  deleteActivity(): void {
    this.store.dispatch(deleteActivity({id: this.notification.id.toString()}));
  }

}
