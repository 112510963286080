<mat-card appearance="outlined" class="mb-3" [ngClass]="{'active': !notification.read }">
  <mat-card-title-group>
    <div class="flex align-items-center justify-content-center p-3 pb-0">
      <mat-card-subtitle class="title mb-0">
        <small>
          {{ notification.data.product.name }}
        </small>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
  <mat-card-content>
    @switch (notification.type) {
      @case ('scenarioJobUpdated') {
        <app-scenario-job [notification]="notification"></app-scenario-job>
      }
      @default {
        <div>
          <p>{{ notification.data.fileName }}</p>
          {{ notification.message }}
        </div>
      }
    }
  </mat-card-content>
</mat-card>
