<div [@.disabled]="true"> <!-- Disable animations -->
  <mat-tab-group mat-stretch-tabs>
    @if (myActivities$ | async; as myActivities) {
      <mat-tab [label]="'activity.feed.mine' |  translate">
          @for (activity of myActivities; track activity.id) {
            <app-activity-notification [notification]="activity"></app-activity-notification>
          } @empty {
          <div class="text-center mt-5">
            <mat-icon class="large-icon">info</mat-icon><br/>
            <p class="text-grey" translate="activity.feed.none"></p>
          </div>
        }
      </mat-tab>
    }
    @if (otherActivities$ | async; as otherActivities) {
      <mat-tab [label]="'activity.feed.other' |  translate">
          @for (activity of otherActivities; track activity.id) {
            <app-activity-notification [notification]="activity"></app-activity-notification>
          } @empty {
          <div class="text-center mt-5">
            <mat-icon class="large-icon">info</mat-icon><br/>
            <p class="text-grey" translate="activity.feed.none"></p>
          </div>
        }
      </mat-tab>
    }
  </mat-tab-group>
</div>

