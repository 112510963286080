import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivityState, Activity, selectOthersActivities, selectMyActivities } from '../store';

@Component({
  selector: 'app-activity-content',
  templateUrl: './activity-content.component.html',
  styleUrls: ['./activity-content.component.scss']
})
export class ActivityContentComponent implements OnInit {
  isDisabled = true;
  myActivities$: Observable<Activity[]>;
  otherActivities$: Observable<Activity[]>;

  constructor(private store: Store<ActivityState>) {
  }

  ngOnInit(): void {
    this.myActivities$ =  this.store.select(selectMyActivities)
    this.otherActivities$ = this.store.select(selectOthersActivities)
  }

}
