<main class="w-full h-screen flex justify-between overflow-hidden font-body bg-slate-950" 
  [ngClass]="{'dark': (isDarkMode$ | async), 'group/container themeable': isThemeable()}"
  cdkOverlayOrigin 
  #trigger="cdkOverlayOrigin">
  <div class="flex justify-between z-50 sticky top-0 bottom-0 border-r border-slate-800/60">
    <router-outlet name="sidebar"></router-outlet>
  </div>

  <section cdkScrollable class="flex-1 flex flex-col justify-between relative overflow-y-auto">
    <div class="flex-1 flex flex-col justify-between bg-white dark:bg-slate-950 transition-all">
      <div class="sticky top-0 z-40 py-2.5 pl-8 pr-10 bg-white/90 dark:bg-slate-950/90 backdrop-blur-sm text-black/70 dark:text-white/70 dark:hover:text-white transition-all flex items-center justify-between gap-5 border-b border-gray-100 dark:border-slate-950">
        <div class="flex-1">
          <router-outlet name="breadcrumbs"></router-outlet>
        </div>
        <app-user-orgs-menu />
      </div>
      <header class="w-full text-sm px-10 dark:text-white border-b dark:border-transparent">
        <div class="-mx-10">
          <router-outlet name="notifications"></router-outlet>
        </div>
        <router-outlet name="product-page-header"></router-outlet>
      </header>
      <div class="grow py-6 px-10 relative bg-soft-blue transition-all flex flex-col justify-between" 
        [ngClass]="{'dark:bg-slate-950': isThemeable()}">
        <div class="flex-1">
          <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </section>
</main>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="toggled$ | async">
  <div class="fixed right-0 bottom-0 top-0 fade-in-left overflow-y-auto w-[370px] bg-white shadow-lg z-[999] p-4 space-y-8">
    <div class="flex items-center justify-between">
      <div class="font-medium text-sm flex items-center space-x-2.5">
        <app-svg-images svgName="bell-alert" class="h-5"></app-svg-images>
        <span>Notifications</span>
      </div>
      <button class="p-1 active" (click)="toggleActivityFeed()" class="h-5">
        <app-svg-images svgName="x-mark"></app-svg-images>
      </button>
    </div>
    <div class="space-y-3">
      <app-activity-content></app-activity-content>
    </div>
  </div>
</ng-template>